import { Attributes, List, LookupBase, Misc, NeoModel, Rules } from '@singularsystems/neo-core';
import CashTransactionLookup from '../CashTransactionLookup';

@NeoModel
export class BuyDetails extends LookupBase {

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public transferDate: Date | null = null;

    @Attributes.Float()
    public quantity: number = 0;

    @Attributes.Float()
    public pricePerUnit: number = 0;
    
    @Attributes.Float()
    public readonly brokerage: number = 0;

    @Attributes.Float()
    public readonly brokerageVat: number = 0;

    @Attributes.Float()
    public readonly transferTax: number = 0;

    // Client only properties / methods

    @Attributes.Float()
    public get value() {
        return this.quantity * this.pricePerUnit;
    }
}

@NeoModel
export default class TradeRequestAdminExtraDetailsLookup extends LookupBase {

    public payrollName: string = "";

    public companyName: string = "";

    @Attributes.Nullable()
    @Attributes.Float()
    public closingPrice: number | null = null;

    @Attributes.ChildObject(BuyDetails)
    public buyDetails: BuyDetails | null = null;

    public usesWavPrice: boolean = false;

    @Attributes.Nullable()
    @Attributes.Float()
    public linkedWavPrice: number | null = null;

    public cashTransactions = new List(CashTransactionLookup);

    // Client only properties / methods
}