import React from 'react';
import { NeoGrid, Views } from '@singularsystems/neo-react';
import SecondmentsVM from './SecondmentsVM';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ContactUsView from '../../../Clients/Participants/Views/ContactUsView';

@observer
export default class SecondmentsView extends Views.ViewBase<SecondmentsVM> {

    constructor(props: unknown) {
        super("Global locations", SecondmentsVM, props);
    }

    public get headerTitle() {
        return "My Profile";
    }

    public render() {
        return (
            <div className="more-padding">
			    <h2>Global locations</h2>

                {!this.taskRunner.isBusy && this.viewModel.secondments.length === 0 &&
                    <p className="my-4">
                        According to our records you have not been employed in multiple countries.  
                        It is important that this information is correct as the Payroll Team will be performing a time apportionment calculation when you trade. 
                        The time apportionment calculation determines the taxable share gain allocation per location worked for payroll processing. 
                        Details of this calculation will be provided to you for your records once completed. 
                        If you have been employed in multiple countries, please contact the <Link to={this.navigation.getPathForView(ContactUsView)}>Shares Office</Link> so that this information is updated.
                    </p>}

				{this.viewModel.secondments.length > 0 &&
                    <div>
                        <p className="my-4">
                            According to our records you have been employed in multiple countries. 
                            Below details the employment information we have on record for you. 
                            It is important that this information is correct as the Payroll Team will be performing a time apportionment calculation when you trade. 
                            The time apportionment calculation determines the taxable share gain allocation per location worked for payroll processing. 
                            Details of this calculation will be provided to you for your records once completed. 
                            Should the below information be incorrect, please contact the <Link to={this.navigation.getPathForView(ContactUsView)}>Shares Office</Link> so that this information is updated.
                        </p>

                        <NeoGrid.Grid items={this.viewModel.secondments} className="bold-headings">
                            {(item, meta) => (
                                <NeoGrid.Row>
                                    <NeoGrid.Column label="Country" className="text-heading" display={meta.countryName} />
                                    <NeoGrid.Column label="From Date" display={meta.startDate} />
                                    <NeoGrid.Column label="To Date" display={meta.endDate} />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                    </div>}
            </div>
        );
    }
}