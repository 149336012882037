import PortfolioBalanceLookup from './PortfolioBalanceLookup';

export interface IGroupedBalance {
    incentiveSchemeId: number;

    instrumentId: number;

    allowedExerciseDays: number | null;
}

export default class CalculatorDataRequest {

    public groupedBalances: IGroupedBalance[] = [];

    // Client only properties / methods

    public static fromTrancheBalances(balances: PortfolioBalanceLookup[]) {
        const request = new CalculatorDataRequest();

        const grouped = balances.groupBy(b => `${b.incentiveSchemeId}.${b.instrumentId}.${b.allowedExerciseDays ?? -1}`, (key, item) => item);
        request.groupedBalances = grouped.map(g => ({
            incentiveSchemeId: g.incentiveSchemeId,
            instrumentId: g.instrumentId,
            allowedExerciseDays: g.allowedExerciseDays
        }));

        return request;
    }
}