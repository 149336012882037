import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../TransactionsTypes';
import TradeRequestCommand from '../Models/Trading/Commands/TradeRequestCommand';
import ParticipantBrokerDetail from '../../Common/Models/ParticipantBrokerDetail';
import CancelTradeResult from '../../Common/Models/Trading/CancelTradeResult';
import LinkedTradeRequestsCommand from '../Models/Trading/Commands/LinkedTradeRequestsCommand';
import { TradeLinkSaveResult, TradeRequestSaveResult } from '../Models/Trading/Lookups/TradeLinkSaveResult';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import CashTransactionLookup from '../../Common/Models/CashTransactionLookup';
import UpdateParticipantBrokerDetailCommand from '../../Common/Models/UpdateParticipantBrokerDetailCommand';
import TradeRequestAdminExtraDetailsLookup from '../../Common/Models/Trading/TradeRequestAdminExtraDetailsLookup';

export interface ITradeApiClient {

    /**
     * Submits a trade request.
     * @param tradeRequest Trade request.
     * @returns Result.
     */
    submitTrade(tradeRequest: Model.PartialPlainObject<TradeRequestCommand>): AxiosPromise<Model.PlainObject<TradeRequestSaveResult>>;

    /**
     * Submits a linked trade request.
     * @param command Command.
     * @returns Trade ref no for each trade.
     */
    submitLinkedTrade(command: Model.PartialPlainObject<LinkedTradeRequestsCommand>): AxiosPromise<Model.PlainObject<TradeLinkSaveResult>>;

    /**
     * Cancels a trade request.
     * @param tradeRequestId Trade request id.
     * @returns Task.
     */
    cancelTrade(tradeRequestId: number): AxiosPromise<Model.PlainObject<CancelTradeResult>>;

    /**
     * Gets the current broker details for a participant.
     * @returns Broker details.
     */
    getParticipantBrokerDetails(participantBrokerDetailId?: number | null, tradeRequestId?: number | null): AxiosPromise<Model.PlainTrackedObject<ParticipantBrokerDetail>>;

    /**
     * Update the broker details of the current participant
     * @param participantBrokerDetailCommand Broker details.
     * @returns Task.
     */
    updateParticipantBrokerDetails(participantBrokerDetailCommand: Model.PartialPlainObject<UpdateParticipantBrokerDetailCommand>): AxiosPromise<Model.PlainTrackedObject<ParticipantBrokerDetail>>;

    /**
     * Gets a trade request for an authenticated participant.
     * Cash transactions child list will be populated.
     * @param tradeRequestId Trade request id.
     * @returns Trade request.
     */
    getTradeRequest(tradeRequestId: number): AxiosPromise<Model.PlainObject<TradeRequestLookup>>;

    /**
     * Gets a list of transactions for a trade request.
     * @param tradeRequestId Trade request id.
     * @returns List of cash transactions.
     */
    getTradeCashTransactions(tradeRequestId: number): AxiosPromise<Array<Model.PlainObject<CashTransactionLookup>>>;

    /**
     * Gets a list of transactions for a trade request.
     * @param tradeRequestId Trade request id.
     * @returns List of cash transactions.
     */
    getTradeRequestExtraDetails(tradeRequestId: number): AxiosPromise<Model.PlainObject<TradeRequestAdminExtraDetailsLookup>>;

    /** 
     * Deactivate the specified participant broker details
     * @param participantBrokerDetailId The specific broker detail.
     * @returns Task.
     */
    deactivateParticipantBrokerDetails(participantBrokerDetailId: number): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class TradeApiClient extends Data.ApiClientBase implements ITradeApiClient {

    constructor(config = AppService.get(Types.Shared.Config)) {
        super(`${config.TransactionsApi.ApiPath}/Trade`);
    }

    public submitTrade(tradeRequest: Model.PartialPlainObject<TradeRequestCommand>): AxiosPromise<Model.PlainObject<TradeRequestSaveResult>> {
        return this.axios.post(`${this.apiPath}/SubmitTrade`, tradeRequest);
    }

    public submitLinkedTrade(command: Model.PartialPlainObject<LinkedTradeRequestsCommand>): AxiosPromise<Model.PlainObject<TradeLinkSaveResult>> {
        return this.axios.post(`${this.apiPath}/SubmitLinkedTrade`, command);
    }

    public cancelTrade(tradeRequestId: number): AxiosPromise<Model.PlainObject<CancelTradeResult>> {
        return this.axios.post(`${this.apiPath}/CancelTrade/${tradeRequestId}`);
    }

    public getParticipantBrokerDetails(participantBrokerDetailId?: number | null, tradeRequestId?: number | null): AxiosPromise<Model.PlainTrackedObject<ParticipantBrokerDetail>> {
        let query = "";
        if (tradeRequestId) {
            query = `?tradeRequestId=${tradeRequestId}`;
        }

        return this.axios.get(`${this.apiPath}/ParticipantBrokerDetails/${participantBrokerDetailId ?? ""}${query}`);
    }

    public updateParticipantBrokerDetails(participantBrokerDetailCommand: Model.PartialPlainObject<UpdateParticipantBrokerDetailCommand>): AxiosPromise<Model.PlainTrackedObject<ParticipantBrokerDetail>> {
        return this.axios.post(`${this.apiPath}/updateParticipantBrokerDetails`, participantBrokerDetailCommand);
    }

    public getTradeRequest(tradeRequestId: number): AxiosPromise<Model.PlainObject<TradeRequestLookup>> {
        return this.axios.get(`${this.apiPath}/tradeRequest/${tradeRequestId}`);
    }

    public getTradeCashTransactions(tradeRequestId: number): AxiosPromise<Array<Model.PlainObject<CashTransactionLookup>>> {
        return this.axios.get(`${this.apiPath}/tradeRequestCashTransactions/${tradeRequestId}`);
    }

    public getTradeRequestExtraDetails(tradeRequestId: number): AxiosPromise<Model.PlainObject<TradeRequestAdminExtraDetailsLookup>> {
        return this.axios.get(`${this.apiPath}/tradeRequestExtraDetails/${tradeRequestId}`);
    }

    public deactivateParticipantBrokerDetails(participantBrokerDetailId: number): AxiosPromise {
        return this.axios.post(`${this.apiPath}/deactivateParticipantBrokerDetails?participantBrokerDetailId=${participantBrokerDetailId}`);
    }

    // Client only properties / methods
}