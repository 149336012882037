import React from 'react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../../../AwardsTypes';

import { Misc, Data, NumberUtils, NeoModel, Utils, Validation, Attributes } from '@singularsystems/neo-core';
import MyAwardLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';

import MyAwardsSelectionGrid from './MyAwardsSelectionGrid';
import MyAwardLookupCriteria from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria';
import MyAwardsVMBase from '../MyAwardsVMBase';
import { IReactionDisposer, reaction } from 'mobx';
import UpdateParticipantSelectionCommand from '../../../Models/ParticipantOffers/Commands/UpdateParticipantSelectionCommand';
import { NotificationDuration } from '../../../../../App/Models/Enums/NotificationDuration';
import ValidationDisplay from '../../../../../App/Common/Components/Application/ValidationDisplay';
import { ParticipantAwardSelectionOption } from '../../../../Common/Models/Enums/ParticipantAwardSelectionOption';

interface IMyAwardOptionSelectionComponentProps {
    viewModel: MyAwardOptionSelectionComponentVM;
}

@NeoModel
export class MyAwardOptionSelectionComponentVM extends MyAwardsVMBase {

    constructor(

        private baseSetupData: (data: MyAwardLookup[]) => void,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private commandClient = AppService.get(Types.Awards.ApiClients.ParticipantAcceptanceCommandApiClient),
        private errorHandler = AppService.get(Types.Neo.UI.ErrorHandler)) {

        super(taskRunner);
    }

    private criteria = new MyAwardLookupCriteria();

    public pageManager = new Data.PageManager(this.criteria, MyAwardLookup, (request) => this.myAwardsApiClient.getPendingAwards(request, this.pendingAwardsIncludes), {
        pageSize: 100,
        initialTaskRunner: this.taskRunner,
        sortBy: "awardDate",
        sortAscending: true,
        afterFetch: (data) => this.setupAwardData(data),
    });

    public async setupAwards(participantOfferId: number) {
        // need to load the full list of this participant offer, just in case the offer is split over multiple pages
        this.criteria.participantOfferId = participantOfferId;
        await this.pageManager.refreshData();
    }

    private awardsValid(data: MyAwardLookup[]) {
        for (const award of data) {
            if (!award.isValid) {
                return false;
            }
        }
        return true;
    }

    protected async setupAwardData(data: MyAwardLookup[]) {
        await this.baseSetupData(data);

        for (const award of data) {
            let madeChanges = false;
            if ((award.selectedOption === null && award.defaultOption) ||
                award.selectedOption === ParticipantAwardSelectionOption.Decline) {
                award.selectedOption = award.defaultOption;
                madeChanges = true;
            }
            if (award.selectedPercent === null && award.optionDefaultPercentage) {
                award.selectedPercent = award.optionDefaultPercentage * award.instrumentOptionDefaultPercentage;
                madeChanges = true;
            }

            if (madeChanges) {
                this.saveSelectionChanges(award);
            }
        }

        this.setupChangeObservers(data);
    }

    public changeDisposers = new Array<IReactionDisposer>();

    private setupChangeObservers(data: MyAwardLookup[]) {
        this.disposeChangeDisposers();
        for (const award of data) {
            this.changeDisposers.push(reaction(() => {
                return {
                    optionInstrumentPriceId: award.awardOptionInstrumentPriceId,
                    selectedOption: award.selectedOption,
                    percent: award.selectedPercent,
                };
            }, (result) => {
                if (result.selectedOption) {
                    var award = this.pageManager.data.find(o => { return o.awardOptionInstrumentPriceId === result.optionInstrumentPriceId; });
                    if (award /*&& this.awardsValid TODO: Supposed to be this.awardsValid(this.pageManager.data)? */) {
                        for (const award of data) {
                            this.saveSelectionChanges(award);
                        }
                    }
                }
            }));
        }

        if (this.selectionChangedHandler) {
            this.selectionChangedHandler();
        }
    }

    public selectionChangedHandler: (() => void) | null = null;

    public saveSelectionChanges(award: MyAwardLookup) {
        Utils.debounce(award, async () => {
            await this.saveSelection(award);
        }, 1000);
    }

    public lastChange: string = "";
    public savedChange: string = "";

    private async saveSelection(award: MyAwardLookup) {
        if (this.validator.isValid && award.isValid) {
            const cmd = this.createUpdateParticipantSelectionCommand(award);

            try {
                await this.commandClient.updateParticipantSelection(cmd.toJSObject());
                award.selectedOn = new Date();

                if (this.selectionChangedHandler) {
                    this.selectionChangedHandler();
                }
                this.notifications.addSuccess("Selection updated", null, NotificationDuration.Short);
            }
            catch (error) {
                this.errorHandler.showError(error, Misc.ErrorDisplayType.ShowNotification);
            }
        }
    }

    private createUpdateParticipantSelectionCommand(award: MyAwardLookup) {
        const cmd = new UpdateParticipantSelectionCommand();
        cmd.participantOfferId = award.participantOfferId;
        cmd.awardOptionInstrumentPriceId = award.awardOptionInstrumentPriceId;
        cmd.selectedOption = award.selectedOption;
        cmd.percent = award.selectedPercent!;
        return cmd;
    }


    public dispose() {
        // Note: Reactions must be disposed to prevent memory leaks.
        this.disposeChangeDisposers();
    }

    private disposeChangeDisposers() {
        for (const disposer of this.changeDisposers) {
            disposer();
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        MyAwardOptionSelectionComponentVM.percentagesTotalTooHighRule = rules.add(this.percentagesTotalTooHigh);
        MyAwardOptionSelectionComponentVM.percentagesTotalNot100Rule = rules.add(this.percentagesTotalNot100);
        MyAwardOptionSelectionComponentVM.percentagesNotMultipleOfRequiredIncrement = rules.add(this.percentagesNotMultipleOfRequiredIncrement);
    }

    public totalSelectionPercent() {
        return this.pageManager.data.reduce((sum, current) => sum + (current.selectedOption === ParticipantAwardSelectionOption.Accept ? current.selectedPercent ?? 0 : 0), 0);
    }

    private percentagesTotalTooHigh(context: Validation.IRuleContext) {
        const sumValue = this.pageManager.data.reduce((sum, current) => sum + (current.selectedOption === ParticipantAwardSelectionOption.Accept ? current.selectedPercent ?? 0 : 0), 0);
        if (sumValue > 1) {
            context.addError(`The percentages must not exceed 100% (currently ${NumberUtils.format(sumValue, "##0.##%")})`);
        }
    }

    private percentagesTotalNot100(context: Validation.IRuleContext) {
        const sumValue = this.pageManager.data.reduce((sum, current) => sum + (current.selectedOption === ParticipantAwardSelectionOption.Accept ? current.selectedPercent ?? 0 : 0), 0);
        if (sumValue < 1) {
            context.addWarning(`The percentages are not at 100% (currently ${NumberUtils.format(sumValue, "##0.##%")}), you could be missing out!`);
        }
    }

    private percentagesNotMultipleOfRequiredIncrement(context: Validation.IRuleContext) {
        for (const award of this.pageManager.data) {
            // Multiply the values by 100 before doing the mod to handle JavaScript's floating point arithmetic
            if (award.selectedPercent !== 0 &&  award.requiredAcceptanceIncrement !== 0 && ((award.selectedPercent! * 100) % (award.requiredAcceptanceIncrement! * 100) !== 0)) {
                context.addError(`The selected percentages required for selection have to be multiples of ${NumberUtils.format(this.pageManager.data[0].requiredAcceptanceIncrement, "##0.##%")}`);
                break;
            }
        }
    }

    @Attributes.NoTracking()
    public static percentagesTotalTooHighRule: Validation.IRule | null = null;

    @Attributes.NoTracking()
    public static percentagesTotalNot100Rule: Validation.IRule | null = null;

    @Attributes.NoTracking()
    public static percentagesNotMultipleOfRequiredIncrement: Validation.IRule | null = null;
}

@observer
export default class MyAwardOptionSelectionComponent extends React.Component<IMyAwardOptionSelectionComponentProps> {

    private appLayout = AppService.get(Types.Shared.Services.AppLayout);

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <div>
                <MyAwardsSelectionGrid
                    pageManager={viewModel.pageManager}
                    viewModel={viewModel}
                    hideFaceValue={!viewModel.pageManager.data.some(c => c.useExpectedValue)}
                    showSelection={false}
                    allowSort={false}
                    config={viewModel.awardModuleConfig.myAwards.grid} />

                {viewModel.pageManager.data.length > 0 &&
                    <div className="pt-1 pb-1 selection-alerts">
                        <ValidationDisplay model={viewModel} rule={MyAwardOptionSelectionComponentVM.percentagesTotalTooHighRule} />
                        <ValidationDisplay model={viewModel} rule={MyAwardOptionSelectionComponentVM.percentagesTotalNot100Rule} />
                        <ValidationDisplay model={viewModel} rule={MyAwardOptionSelectionComponentVM.percentagesNotMultipleOfRequiredIncrement} />
                    </div>}
            </div>
        )
    }
}