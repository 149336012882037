import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../TransactionsTypes';
import PortfolioBalanceLookup from '../Models/Portfolio/PortfolioBalanceLookup';
import AppDataLookup from '../Models/Portfolio/AppDataLookup';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import CalculatorDataRequest from '../Models/Portfolio/CalculatorDataRequest';
import CalculatorData from '../Models/Portfolio/CalculatorData';
import TradeRequestCriteria from '../Models/Trading/Criteria/TradeRequestCriteria';
import AwardCostsLookup from '../Models/Portfolio/AwardCostsLookup';
import AwardCostsCriteria from '../../Common/Models/AwardCostsCriteria';

export interface IPortfolioApiClient {

    /**
     * Gets a list of tranches with a balance for a participant.
     * @returns List of balances by tranche id.
     */
    getTrancheBalancesForParticipant(): AxiosPromise<Array<Model.PlainObject<PortfolioBalanceLookup>>>;

    /**
     * Gets a list of tranches with a balance for a participant.
     * @param participantId The Participant Id
     * @returns List of balances by tranche id.
     */
    getTrancheBalancesWithUnitsForParticipant(participantId: string): AxiosPromise<Array<Model.PlainObject<PortfolioBalanceLookup>>>;

    /** 
     * Gets a list of award costs for a specific tranche
     * @returns A list of award costs
     */
    getAwardCostsForTranche(awardCostsCriteria: Model.PartialPlainNonTrackedObject<AwardCostsCriteria>): AxiosPromise<Model.PlainObject<AwardCostsLookup>>;

    /**
     * Gets the participants app startup data.
     * @param viewCurrencyId Currency the tranches will be converted to.
     * @returns App data lookup.
     */
    getParticipantsAppData(viewCurrencyId: number | null, loadPendingTradeRequests: boolean): AxiosPromise<Model.PlainObject<AppDataLookup>>;

    /**
     * Gets the pending trade requests for a participant.
     * @returns List of pending trades.
     */
    getPendingTradeRequests(): AxiosPromise<Array<Model.PlainObject<TradeRequestLookup>>>;

    /**
     * Gets a paged list of trade requests for the current participant.
     * @param request Page request.
     * @returns Page.
     */
    getTradeRequestsPagedAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TradeRequestCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TradeRequestLookup>>>;

    /**
     * Gets the agreement text for a trade agreement id.
     * @param tradeAgreementId Trade agreement id.
     * @returns Agreement text.
     */
     getTradeAgreementText(tradeAgreementId: number): AxiosPromise<string>;

    /**
     * Get calculation data for the calculator / trading.
     * @param dataRequest Request parameters.
     * @returns Calculation data.
     */
    processCalculatorData(dataRequest: Model.PartialPlainObject<CalculatorDataRequest>): AxiosPromise<Model.PlainObject<CalculatorData>>;

    // Client only properties / methods
}

@injectable()
export default class PortfolioApiClient extends Data.ApiClientBase implements IPortfolioApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.TransactionsApi.ApiPath}/Portfolio`);
    }

    public getTrancheBalancesForParticipant(): AxiosPromise<Array<Model.PlainObject<PortfolioBalanceLookup>>> {
        return this.axios.get(`${this.apiPath}/trancheBalances`);
    }

    public getTrancheBalancesWithUnitsForParticipant(participantId: string): AxiosPromise<Array<Model.PlainObject<PortfolioBalanceLookup>>> {
        return this.axios.get(`${this.apiPath}/trancheBalancesWithUnits/${encodeURIComponent(participantId)}`);
    }

    public getAwardCostsForTranche(awardCostsCriteria: Model.PartialPlainNonTrackedObject<AwardCostsCriteria>): AxiosPromise<Model.PlainObject<AwardCostsLookup>> {
        return this.axios.get(`${this.apiPath}/awardCosts?${Utils.getQueryString(awardCostsCriteria)}`);
    }

    public getParticipantsAppData(viewCurrencyId: number | null, loadPendingTradeRequests: boolean): AxiosPromise<Model.PlainObject<AppDataLookup>> {
        let queryString = `loadPendingTradeRequests=${loadPendingTradeRequests}`;
        if (viewCurrencyId) {
            queryString += `&viewCurrencyId=${viewCurrencyId}`;
        }
        return this.axios.get(`${this.apiPath}/startup-data?${queryString}`);
    }

    public getPendingTradeRequests(): AxiosPromise<Array<Model.PlainObject<TradeRequestLookup>>> {
        return this.axios.get(`${this.apiPath}/pending-trades`);
    }

    public getTradeRequestsPagedAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TradeRequestCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TradeRequestLookup>>> {
        return this.axios.get(`${this.apiPath}/paged-trades?${Utils.getQueryString(request)}`);
    }

    public getTradeAgreementText(tradeAgreementId: number): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/trade-agreement-text/${tradeAgreementId}`);
    }

    public processCalculatorData(dataRequest: Model.PartialPlainObject<CalculatorDataRequest>): AxiosPromise<Model.PlainObject<CalculatorData>> {
        return this.axios.post(`${this.apiPath}/calculation-data`, dataRequest);
    }
    // Client only properties / methods
}