import { AppService, Types } from '../../../TransactionsTypes';
import { ModelBase, NeoModel, ITaskRunner } from '@singularsystems/neo-core';
import TradeRequestLookup from '../../../Models/Trading/Lookups/TradeRequestLookup';
import { NotificationDuration } from '../../../../../App/Models/Enums/NotificationDuration';
import { BrokerDetailsHelper } from '../../../../Common/Services/BrokerDetailsHelper';
import TradeRequestAdminExtraDetailsLookup from '../../../../Common/Models/Trading/TradeRequestAdminExtraDetailsLookup';

export interface IPendingTradeRequestComponentProps {
    taskRunner: ITaskRunner;
}

@NeoModel
export class PendingTradeRequestsComponentModel extends ModelBase {

    constructor(
        options: IPendingTradeRequestComponentProps,
        private tradeApiClient = AppService.get(Types.Transactions.ApiClients.TradeApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public portfolioService = AppService.get(Types.Transactions.Services.PortfolioService)) {
        super();

        this.taskRunner = options.taskRunner;
    }

    private taskRunner: ITaskRunner;

    public get showTradeDetailsButton() {
        return this.portfolioService.appData?.settings.showParticipantTradeDetailsButton;
    }

    /** Used to edit broker details. */
    public editingTrade: TradeRequestLookup | null = null;

    /** Used to show the trade detail modal. */
    public tradeRequestWithTransactions: TradeRequestLookup | null = null;

    public async cancelTrade(tradeRequest: TradeRequestLookup) {
        await this.taskRunner.run(async () => {
            const result = (await this.tradeApiClient.cancelTrade(tradeRequest.tradeRequestId)).data;
            tradeRequest.sellStatus = result.tradeSellStatus;
            tradeRequest.buyStatus = result.tradeBuyStatus;

            if (tradeRequest.tradeRequestLinkId) {
                const linked = this.portfolioService.appData!.pendingTradeRequests.filter(c => c.tradeRequestLinkId === tradeRequest.tradeRequestLinkId);
                for (let linkedTrade of linked) {
                    linkedTrade.sellStatus = result.tradeSellStatus;
                    linkedTrade.buyStatus = result.tradeBuyStatus;
                }
            }

            this.notifications.addSuccess("Cancel", "Cancellation request processed successfully.", NotificationDuration.Standard);
        });

        // Need to update the tranche list.
        this.portfolioService.expire();
    }

    public async beginEditBrokerDetails(tradeRequest: TradeRequestLookup) {

        const response = await this.taskRunner.waitFor(this.tradeApiClient.getParticipantBrokerDetails(tradeRequest.participantBrokerDetailId, tradeRequest.tradeRequestId));

        BrokerDetailsHelper.setBrokerDetails(tradeRequest, response.data);
        this.editingTrade = tradeRequest;
    }

    public async saveBrokerDetails() {

        this.taskRunner.run(async () => {
            if (this.editingTrade && this.editingTrade.editingBrokerDetails) {
                const result = await this.tradeApiClient.updateParticipantBrokerDetails(this.editingTrade.editingBrokerDetails.toJSObject());
                this.editingTrade.participantBrokerDetailId = result.data.participantBrokerDetailId;
                this.editingTrade = null;

                this.notifications.addSuccess("Broker details", "Broker details updated successfully.", NotificationDuration.Standard);
            }
        });
    }

    public async showTradeRequest(tradeRequest: TradeRequestLookup) {

        if (!tradeRequest.extraDetails) {
            await this.loadExtraDetails(tradeRequest);
        }

        if (!tradeRequest.hasLoadedCashTransactions) {
            const result = await this.taskRunner.waitFor(this.tradeApiClient.getTradeCashTransactions(tradeRequest.tradeRequestId));
            tradeRequest.hasLoadedCashTransactions = true;
            tradeRequest.cashTransactions.set(result.data);
        }
        this.tradeRequestWithTransactions = tradeRequest;
    }

    public async loadExtraDetails(tradeRequest: TradeRequestLookup) {

        if (!tradeRequest.extraDetails) {
            const result = await this.taskRunner.waitFor(this.tradeApiClient.getTradeRequestExtraDetails(tradeRequest.tradeRequestId));
            tradeRequest.extraDetails = TradeRequestAdminExtraDetailsLookup.fromJSObject<TradeRequestAdminExtraDetailsLookup>(result.data);
        }
    }
}