import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { AllowedExercisePriceType } from '../../../Common/Models/Enums/AllowedExercisePriceType';
import { AllowedTradeType } from '../../../Common/Models/Enums/AllowedTradeType';
import InstrumentOptionLookup from '../Instruments/InstrumentOptionLookup';
import { TaxDirectiveProcessType } from '../Enums/TaxDirectiveProcessType';
import { ExercisePriceType } from '../Enums/ExercisePriceType';
import TimePeriod from '../../../../App/Common/Models/TimePeriod';
import { LossDisplayType } from '../Enums/LossDisplayType';
import { CertificateHandlingType } from '../Enums/CertificateHandlingType';
import { TradeType } from '../Enums/TradeType';
import { SaleConversionType } from '../Enums/SaleConversionType';
import { AwardMarketPriceOption } from '../../../Common/Models/Enums/AwardMarketPriceOption';

@NeoModel
export default class IncentiveSchemeLookup extends LookupBase {

    public incentiveSchemeId: number = 0;

    public incentiveSchemeName: string = "";

    @Attributes.Nullable()
    public incentiveSchemeTypeId: number | null = null;

    @Attributes.Nullable()
    public defaultVestingScheduleId: number | null = null;

    public incentiveSchemeTypeName: string = "";

    public allowedSellPriceType: AllowedExercisePriceType = AllowedExercisePriceType.NoLimitAllowed;

    public allowedTradeType: AllowedTradeType = AllowedTradeType.Partial;

    public trackingInstrumentCode: string = "";

    public settlementInstrumentCode: string = "";

    public requiresBulkTrading: boolean = false;

    @Attributes.Float()
    public sellToCoverBufferPriceReductionPercentage: number = 0;

    @Attributes.ChildObject(TimePeriod)
    public openTradeInstructionBeforeVesting: TimePeriod | null = null;

    @Attributes.ChildObject(TimePeriod)
    public closeTradeInstructionBeforeVesting: TimePeriod | null = null;

    public defaultBulkTradeType: TradeType | null = null;

    @Attributes.ChildObject(TimePeriod)
    public openTradeReminderPeriod: TimePeriod | null = null;

    @Attributes.ChildObject(TimePeriod)
    public closeTradeReminderPeriod: TimePeriod | null = null;

    public exercisePriceType: ExercisePriceType | null = null;

    @Attributes.Nullable()
    public instrumentPriceTypeId: number | null = null;

    public allowPreviousPrice: boolean = false;

    public taxDirectiveProcessType: TaxDirectiveProcessType | null = null;

    public instruments = new List(InstrumentOptionLookup);

    public allowSell: boolean = true;

    public allowBuy: boolean = true;

    public allowSellToCover: boolean = true;

    public isCashSettled: boolean = false;

    public incentivePlanId: number | null = null;

    @Attributes.Nullable()
    public settlementCurrencyId: number | null = null;

    @Attributes.Nullable()
    public trackingCurrencyId: number | null = null;

    public isArchived: boolean = false;

    public hideFromParticipants: boolean = false;

    public isDeniedForUser: boolean = false;

    @Attributes.Nullable()
    public incentiveGroupId: number | null = null;

    public lossDisplayType: LossDisplayType = LossDisplayType.HideLoss;

    public certificateHandlingType: CertificateHandlingType = CertificateHandlingType.None;

    public saleConversionType: SaleConversionType | null = null;

    @Attributes.Nullable()
    public interestTypeId: number | null = null;

    public notionalInterest: boolean = false;

    public awardMarketPriceOption: AwardMarketPriceOption = AwardMarketPriceOption.None

}