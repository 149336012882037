import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import AcceptAwardsVM, { AcceptAwardViewMode } from './AcceptAwardsVM';
import { observer } from 'mobx-react';
import MyAwardsGrid from './Components/MyAwardsGrid';
import { Components, Misc, ModalUtils } from '@singularsystems/neo-core';
import { ScreenSize } from '../../../../App/Services/AppLayout';

import MyAwardOptionSelectionComponent from './Components/MyAwardOptionSelectionComponent';
import MyAwardQuestionnaireComponent from './Components/MyAwardQuestionnaireComponent';
import MyAwardDocumentComponent from './Components/MyAwardDocumentComponent';
import MyAwardAcceptSuccessComponent from './Components/MyAwardAcceptSuccessComponent';

import MyAwardsView from './MyAwardsView';
import MyAwardLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';

class AcceptAwardsViewParams {
    award = {};
    mode = {};
    document = {};
}

@observer
export default class AcceptAwardsView extends Views.ViewBase<AcceptAwardsVM, AcceptAwardsViewParams> {

    public static params = new AcceptAwardsViewParams();

    constructor(props: unknown) {
        super("Accept Awards", AcceptAwardsVM, props);
    }

    public showBreadcrumb() {
        return true;
    }

    public async viewParamsUpdated() {
        if (this.viewParams.mode) {
            const selectedMode = this.viewParams.mode.asString().toLowerCase();
            if (selectedMode === AcceptAwardViewMode.Awards) {
                this.clearViewParams();
            } else {
                var participantOfferId = this.viewParams.award.asNullableInt();
                const viewMode = selectedMode as AcceptAwardViewMode;
                if (!participantOfferId || !viewMode || viewMode === AcceptAwardViewMode.Awards) {
                    // no offer set, clear the mode
                    this.clearViewParams();
                } else {
                    if (viewMode === AcceptAwardViewMode.Select) {
                        this.viewModel.loadAwardOptionSelectView(participantOfferId)
                        this.viewParams.mode.description = this.capitalise(viewMode);
                    }
                    else if (viewMode === AcceptAwardViewMode.Questionnaire) {
                        this.viewModel.loadAwardQuestionnaireView(participantOfferId)
                        this.viewParams.mode.description = this.capitalise(viewMode);
                    }
                    else if (viewMode === AcceptAwardViewMode.Document || viewMode === AcceptAwardViewMode.AcceptedAwardSummary ) {
                        let participantOfferAwardDocumentId = this.viewParams.document.asNullableInt();
                        let incentiveSchemeDocumentId: number | null = null;
                        if (!participantOfferAwardDocumentId) {
                            if (this.viewParams.document.asString().toLowerCase() === "letter") {
                                participantOfferAwardDocumentId = 0;
                            }
                            else if (this.viewParams.document.asString().toLowerCase().startsWith("scheme-doc-")) {
                                var numText = this.viewParams.document.asString().toLowerCase().replace("scheme-doc-", "");
                                incentiveSchemeDocumentId = parseInt(numText);
                            }
                        }
                        if (participantOfferAwardDocumentId !== null || incentiveSchemeDocumentId) {
                            const loaded = await this.viewModel.loadAwardDocumentView(participantOfferId, participantOfferAwardDocumentId, incentiveSchemeDocumentId);

                            if (loaded) {
                                this.viewParams.mode.description = this.capitalise(viewMode);
                                this.viewParams.document.description = this.viewModel.documentComponentVM.document.documentName;
                            } else {
                                this.clearViewParams();
                            }
                        }
                        else {
                            this.clearViewParams();
                        }
                    }
                }
            }
        } else {
            this.clearViewParams();
        }
    }

    private clearViewParams() {
        this.viewModel.viewMode = AcceptAwardViewMode.Awards;
        this.viewParams.setValues({ award: null, mode: null, document: null });
    }

    private capitalise(word: string) {
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

    private back() {
        if (this.viewParams.mode.value === AcceptAwardViewMode.Document) {
            this.clearViewParams();
        } else {
            this.viewModel.back();
        }
    }

    public render() {
        return (

            <div className="accept-awards">

                {/* DEBUGGING INFO */}
                {/* Step: {this.viewModel.stepIndex + 1} / {this.viewModel.acceptSteps.length} - {this.viewModel.acceptSteps.length > 0 ? this.viewModel.acceptSteps[this.viewModel.stepIndex].mode : "none"}
                <br/>
                ViewMode: {this.viewModel.viewMode} */}

                {/* PENDING AWARDS */}
                {this.viewModel.viewMode === AcceptAwardViewMode.Awards &&
                    <div>
                        <Neo.Loader task={this.viewModel.myAwardInfoTaskRunner} size="small">
                            <div className="row mb-3 ml-2 align-items-end">
                                <div className="col-6">
                                    <h2>{this.viewModel.awardModuleConfig.myAwards.pendingAwardsTitle}</h2>
                                    {!this.viewModel.noData &&
                                        <p>
                                            {this.viewModel.myAwardsInfoLookup.pendingAwardsMessage}
                                        </p>}
                                </div>
                                {this.viewModel.viewMode === AcceptAwardViewMode.Awards &&
                                    <div className="col-6">
                                        {this.viewModel.pendingAwardsPageManager.totalPages > 1 && this.viewModel.pendingAwardsPageManager.data.length > 0 &&
                                            <div className="col pager-holder right-tools">
                                                <Neo.PagerControlsBasic pageManager={this.viewModel.pendingAwardsPageManager} noOfButtons={this.viewModel.getPagerNoOfButtons()} />
                                            </div>}
                                    </div>}
                            </div>
                        </Neo.Loader>

                        {!this.viewModel.noData &&
                            <Neo.Loader task={this.taskRunner}>
                                <MyAwardsGrid
                                    pageManager={this.viewModel.pendingAwardsPageManager}
                                    viewModel={this.viewModel}
                                    showSelection
                                    config={this.viewModel.awardModuleConfig.myAwards.grid}
                                    showAwardDate
                                    viewSelectionHandler={(item) => this.viewParams.setValues({ award: item.participantOfferId, mode: AcceptAwardViewMode.Select })}
                                    viewQuestionnaireHandler={(item) => this.viewParams.setValues({ award: item.participantOfferId, mode: AcceptAwardViewMode.Questionnaire })}
                                    viewDocumentHandler={(award, doc) => this.viewParams.setValues({
                                        award: award.participantOfferId,
                                        mode: AcceptAwardViewMode.Document,
                                        document: (doc.incentiveSchemeDocumentId ? `scheme-doc-${doc.incentiveSchemeDocumentId}` : (doc.participantOfferAwardDocumentId === 0 ? "letter" : doc.participantOfferAwardDocumentId))
                                    })}
                                    showEntityButton={this.viewModel.participantEntities && this.viewModel.participantEntities.length !== 0}
                                    viewEntityHandler={(item) => {
                                        this.viewModel.selectedAward = item;
                                        this.viewModel.showParticipantEntityModal = true;
                                    }}
                                    currentlySelectedEntity={this.viewModel.selectedAward?.participantEntityId ? "Change Selected Entity" : "Select Entity"}
                                />
                            </Neo.Loader>}

                        {this.viewModel.noData &&
                            <Neo.Alert variant="info">
                                <p>
                                    You have no awards to accept.
                                </p>
                                <Neo.Button variant="primary" onClick={() => this.navigation.navigateToView(MyAwardsView)}>View my accepted awards</Neo.Button>
                            </Neo.Alert>}
                    </div>}

                {this.viewModel.selectedAward && this.viewModel.viewMode === AcceptAwardViewMode.Select &&
                    <MyAwardOptionSelectionComponent viewModel={this.viewModel.optionSelectionVM} />}

                {this.viewModel.selectedAward && this.viewModel.viewMode === AcceptAwardViewMode.Questionnaire &&
                    <MyAwardQuestionnaireComponent viewModel={this.viewModel.questionnaireComponentVM} disabled={this.viewModel.viewingAcceptedContent} />}

                {this.viewModel.selectedAward && this.viewModel.viewMode === AcceptAwardViewMode.Document &&
                    <MyAwardDocumentComponent viewModel={this.viewModel.documentComponentVM}
                        readOnly={this.viewModel.viewingAcceptedContent}
                        isAcceptedAwardSummary={true} />}

                {this.viewModel.selectedAward && this.viewModel.viewMode === AcceptAwardViewMode.AcceptedAwardSummary &&
                    <MyAwardDocumentComponent viewModel={this.viewModel.documentComponentVM}
                        readOnly={this.viewModel.viewingAcceptedContent}
                        isAcceptedAwardSummary={true} />}

                {(this.viewModel.viewMode === AcceptAwardViewMode.Success) &&
                    <MyAwardAcceptSuccessComponent viewModel={this.viewModel.acceptSuccessComponentVM}
                        viewQuestionnaireHandler={(item) => this.viewModel.loadAcceptedAwardQuestionnaireView(item)}
                        viewDocumentHandler={(award, doc) => this.viewModel.loadAcceptedAwardDocumentView(award, doc)}
                        viewEntityHandler={(item) => {
                            this.viewModel.selectedAward = item;
                            this.viewModel.showParticipantEntityModal = true;
                        }} />}

                {this.viewModel.selectedAward &&
                    <Neo.Modal
                        title="Choose Entity"
                        bind={this.viewModel.meta.showParticipantEntityModal}
                        acceptButton={{
                            text: "Accept",
                            disabled: this.viewModel.selectedAward!.useParticipantEntity && !this.viewModel.selectedAward!.participantEntityId,
                            tooltip: this.viewModel.selectedAward!.useParticipantEntity && !this.viewModel.selectedAward!.participantEntityId ? "Please select an entity" : "",
                            onClick: () => {
                                if (this.viewModel.selectedAward && this.viewModel.selectedAward.participantEntityId) {
                                    this.viewModel.selectedAward.selectedParticipantEntityName = this.viewModel.participantEntities.find(x => x.participantEntityId === this.viewModel.selectedAward!.participantEntityId)!.entityName;
                                }
                                this.viewModel.showParticipantEntityModal = false;
                            }
                        }}
                        closeButton={{
                            onClick: () => {
                                this.viewModel.showParticipantEntityModal = false;
                            }
                        }}>
                        <p>Please select into which entity you would like to accept the selected award</p>
                        <Neo.RadioList
                            bind={this.viewModel.selectedAward!.meta.useParticipantEntity}
                            radioList={{
                                items: [
                                    { id: false, text: "Accept in Personal Name" },
                                    { id: true, text: "Accept into an Entity" }
                                ],
                                inline: true
                            }} />
                        {this.viewModel.selectedAward!.useParticipantEntity &&
                            <div>
                                <p></p>
                                <Neo.FormGroup
                                    bind={this.viewModel.selectedAward!.meta.participantEntityId}
                                    label="Please confirm which entity to use"
                                    select={{
                                        items: this.viewModel.participantEntities,
                                        valueMember: "participantEntityId",
                                        displayMember: "entityName"
                                    }} />
                            </div>}
                    </Neo.Modal>}

                <div className="accept-awards-footer page-block-alt">
                    {this.viewModel.viewMode === AcceptAwardViewMode.Awards &&
                        <div className="accept-ts-and-cs">
                            <Neo.FormGroup className="large-check-box" bind={this.viewModel.meta.acceptTermsAndConditions} readOnly={false} label="I have read and understand the documentation for my awards." />
                        </div>}

                    <div className="accept-buttons">
                        <div>
                            {this.viewModel.viewMode !== AcceptAwardViewMode.Success && this.viewModel.viewMode !== AcceptAwardViewMode.Awards &&
                                <Neo.Button variant="secondary" onClick={() => this.back()}>Back</Neo.Button>}
                        </div>

                        <div className="right-tools">
                            {this.getActionButtons()}
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private getActionButtons() {
        return this.getActionButtonOptions().map((options, index) => <Neo.Button key={`button-${index}-${options.variant}`} {...options} />)
    }

    private async accept() {
        const entitiesMessage =
            this.viewModel.participantEntities &&
                this.viewModel.participantEntities.length !== 0 &&
                this.viewModel.pageAwardSelector.selectedAwards.some(x => !(x as MyAwardLookup).participantEntityId) ?
                "You have not selected an entity for one or more of your awards, therefore they will be awarded to you in your personal capacity." :
                "";

        if (!this.viewModel.selectedHaveAdditionalAcceptSteps) {
            // show popup
            if (await ModalUtils.showYesNo(
                `Accept Awards?`,
                <div>
                    <p><i className={`mr-2 fa fa-check text-success`} />Once your selection is submitted, it can no longer be changed.</p>

                    {entitiesMessage.length !== 0 &&
                        <p><i className={"mr-2 fa fa-exclamation-triangle text-warning"} />{entitiesMessage}</p>}

                    <p>Accept selected awards?</p>
                </div>) === Misc.ModalResult.Yes) {
                this.viewModel.startAccept();
            }
        } else if (entitiesMessage.length !== 0) {
            if (await ModalUtils.showYesNo(
                `Accept Awards?`,
                <div>
                    <p><i className={"mr-2 fa fa-exclamation-triangle text-warning"} />{entitiesMessage}</p>

                    <p>Accept selected awards?</p>
                </div>) === Misc.ModalResult.Yes) {
                this.viewModel.startAccept();
            }
        } else {
            this.viewModel.startAccept()
        }
    }

    private async decline() {
        if (await ModalUtils.showYesNo(
            `Decline Awards?`,
            <div>
                <p><i className={`mr-2 fa fa-exclamation-triangle text-warning`} />Once your selection is submitted, it can no longer be changed.</p>
                <p>Are you sure you want to want to decline these awards?</p>
            </div>) === Misc.ModalResult.Yes) {
            this.viewModel.startDecline();
        }
    }

    private hasMultipleInstruments() {
        for (var award of this.viewModel.pendingAwardsPageManager.data) {
            var incentiveScheme = this.viewModel.incentiveSchemes.find(x => x.incentiveSchemeId === award.incentiveSchemeId);
            if (incentiveScheme && incentiveScheme.instruments.length > 1) {
                return true;
            }
        }
        return false;
    }

    private getActionButtonOptions(): Components.IButtonOptions[] {
        let buttons: Components.IButtonOptions[] = [];

        if (this.viewModel.viewMode === AcceptAwardViewMode.Awards) {
            var acceptButtontext = (this.hasMultipleInstruments() && this.viewModel.myAwardsInfoLookup.acceptTotalAwards === 1 ) ? "Make Selection" : "Accept Awards"
            buttons.push({
                variant: "secondary",
                text: this.viewModel.appLayout.currentScreenSize <= ScreenSize.Small ? "Decline" : "Decline awards",
                disabled: !this.viewModel.acceptTermsAndConditions || this.viewModel.pageAwardSelector.selectedAwards.length === 0,
                onClick: () => this.decline(),
            });
            buttons.push({
                variant: "primary",
                text: this.viewModel.appLayout.currentScreenSize <= ScreenSize.Small ? "Accept" : acceptButtontext,
                disabled: !this.viewModel.acceptTermsAndConditions || this.viewModel.pageAwardSelector.selectedAwards.length === 0,
                onClick: () => this.accept(),
            });
        }
        else if (this.viewModel.viewMode === AcceptAwardViewMode.Success) {
            buttons.push({
                variant: "primary", text: "View my awards", onClick: async () => {
                    this.navigation.navigateToView(MyAwardsView);
                }
            });
        }
        else if (this.viewModel.viewMode === AcceptAwardViewMode.AcceptedAwardSummary) {
            buttons.push({
                variant: "primary", text: "Accept", onClick: async () => {
                    this.viewModel.next();
                }
            });
        }
        else if (!this.viewModel.viewingAcceptedContent && this.viewParams.document.value === null) {
            buttons.push({
                variant: "primary", text: "Continue", onClick: async () => {
                    this.viewModel.next();
                }
            });
        }

        if (buttons.length > 3 && this.viewModel.appLayout.currentScreenSize <= ScreenSize.Medium) {
            // make 1 drop down button
            for (const btn of buttons) {
                btn.icon = `minus text-${btn.variant}`;
            }
            buttons = [
                { variant: "primary", text: "Action", menuItems: buttons }
            ];
        }

        return buttons;
    }
}