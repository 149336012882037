import { Attributes, NeoModel } from "@singularsystems/neo-core";
import { runInAction } from "mobx";
import BrokerAccountLookup from "../../Brokers/Lookups/BrokerAccountLookup";
import IncentiveSchemeParticipantLookup from "../../IncentiveSchemeParticipantLookup";
import RatesLookup from "../../RatesLookup";
import { CalculationBase, ITradeableBalance } from "./CalculationBase";
import { TradeType } from "../../Trading/TradeType";
import CalculationTranche from "./CalculationTranche";
import GroupLink from "./GroupLink";

@NeoModel
export default class CalculationGroup extends CalculationBase implements ITradeableBalance {

    public groupKey: string = "";

    public groupName: string = "";

    public totalValue: number = 0;

    public details: CalculationTranche[] = [];

    // TODO: Might not be necessary if everything done through parent.
    @Attributes.Observable(false)
    public groupLink: GroupLink | null = null;

    @Attributes.Observable(false)
    public linkedGroups: CalculationGroup[] = [];

    protected getLinkedRecords() {
        return this.linkedGroups;
    }

    public isExpanded = false;

    public _isSelected = false;

    public get isSelected() {
        return this.groupLink?.isSelected ?? this._isSelected;
    }
    public set isSelected(value: boolean) {
        if (this.groupLink) {
            this.groupLink.isSelected = value;
        } else {
            this._isSelected = value;
        }
    }

    @Attributes.Float()
    public get customPrice() {
        return this.details[0].trancheBalance.customInstrumentPrice;
    }
    public set customPrice(value: number) {
        this.details[0].trancheBalance.customInstrumentPrice = value;
    }

    @Attributes.Integer()
    public get unitsToTrade() {
        return this.sellQuantity + this.buyQuantity;
    }
    public set unitsToTrade(value: number) {
        this.suppressLinkedTrancheSynchronisation(true);

        if (this.lastTradeType === TradeType.Sell) {
            this.sellQuantity = value;
            this.groupLink?.setQuantityOnLinkedGroups(this);
        } else if (this.lastTradeType === TradeType.Buy) {
            this.buyQuantity = value;
            this.groupLink?.setQuantityOnLinkedGroups(this);
        } else if (this.lastTradeType === TradeType.SellToCover) {
            let ratio = value / this.availableBalance;
            this.changeSellToBuyTotalUnits(value);

            this.linkedGroups.forEach(lg => {
                lg.changeSellToBuyTotalUnits(Math.round(lg.availableBalance * ratio));
            });
        }

        this.suppressLinkedTrancheSynchronisation(false);
    }

    private changeSellToBuyTotalUnits(totalUnits: number) {
        this.sellQuantity = Math.ceil(this.sellToBuySellRatio * totalUnits);
        this.buyQuantity = totalUnits - this.sellQuantity;

        // sellToBuySellRatio is based on transacting all units.
        // If transacting a lower portion of units, the brokerage may be a higher percentage, causing proceeds to be negative. 
        for (let i = 0; i < 10; i++) {
            if (this.netProceeds < 0 && this.buyQuantity > 0) {
                this.buyQuantity -= 1
                this.sellQuantity += 1;
            } else {
                break;
            }
        }
    }

    public get vestedTranches() {
        return this.details.filter(c => c.trancheBalance.isVested);
    }

    public get awardName() {
        return this.trancheBalance.awardName;
    }

    public get incentiveSchemeName() {
        return this.trancheBalance.incentiveScheme.incentiveSchemeName;
    }

    @Attributes.Date()
    public get awardDate() {
        return this.trancheBalance.awardDate;
    }

    public get instrumentName() {
        return this.trancheBalance.instrument.instrumentName;
    }

    public get participantEntityName() {
        return this.trancheBalance.participantEntityName;
    }

    @Attributes.Float()
    public get instrumentPrice() {
        if (this.trancheBalance.trackingInstrument && this.trancheBalance.currencySymbol !== "") {
            return this.trancheBalance.trackingInstrumentPrice;
        } else {
            return this.trancheBalance.instrumentPrice;
        }
    }

    public get transactionsScheme(): IncentiveSchemeParticipantLookup | null {
        return this.details[0].transactionsScheme;
    }

    public get brokerAccount(): BrokerAccountLookup {
        return this.details[0].brokerAccount;
    }

    public get rates(): RatesLookup {
        return this.details[0].rates;
    }

    @Attributes.Float()
    public get awardPrice() {
        const unitTotal = this.details.sum(c => c.availableBalance);
        return unitTotal === 0 ? 0 : (this.details.sum(c => c.trancheBalance.awardPrice * c.availableBalance) / unitTotal);
    }

    /** 
     * Currency symbol to use when displaying current price. 
     * For displaying current value / award costs, use `instrument.displayCurrencySymbol`
     */
    public get priceCurrencySymbol() {
        if (this.trackingInstrument && this.trackingInstrument.currencySymbol !== "") {
            return this.trackingInstrument.currencySymbol;
        } else {
            return this.instrument.currencySymbol;
        }
    }

    @Attributes.Float()
    public get availableBalance() {
        return this.details.sum(c => c.availableBalance);
    }

    @Attributes.Float()
    public get expectedValueConverted() {
        return this.details.sum(c => c.trancheBalance.expectedValueConverted);
    }

    @Attributes.Float()
    public get vestedBalance() {
        return this.vestedTranches.sum(c => c.availableBalance);
    }

    @Attributes.Float()
    public get vestedCurrentValue() {
        return this.vestedTranches.sum(c => c.currentValue);
    }

    @Attributes.Float()
    public get vestedCurrentValueConverted() {
        return this.vestedTranches.sum(c => c.currentValueConverted);
    }

    @Attributes.Float()
    public get vestedAwardDebt() {
        return this.vestedTranches.sum(c => c.remainingAwardDebt);
    }

    @Attributes.Float()
    public get vestedAwardDebtConverted() {
        return this.vestedTranches.sum(c => c.remainingAwardDebtConverted);
    }

    @Attributes.Float()
    public get tradedUnits() {
        return this.details.sum(c => c.trancheBalance.tradedUnits);
    }

    @Attributes.Float()
    @Attributes.Display("Award costs")
    public get awardCosts() {
        return this.details.sum(c => c.trancheBalance.awardCosts);
    }

    public get awardDebt() {
        return this.details.sum(c => c.trancheBalance.awardDebt);
    }

    @Attributes.Display("Award costs")
    @Attributes.Float()
    public get remainingAwardDebt() {
        return this.details.sum(c => c.remainingAwardDebt);
    }

    @Attributes.Float()
    public get proportionalAwardDebt() {
        return this.details.sum(c => c.proportionalAwardDebt);
    }

    @Attributes.Float()
    public get vestedProfitLossConverted() {
        return this.vestedTranches.sum(c => c.profitLossConverted);
    }

    @Attributes.Float()
    public get vestedProfitLossConvertedLimited() {
        return this.limitLossToZero(c => c.vestedProfitLossConverted);
    }

    @Attributes.Float()
    public get tradeProceeds() {
        return this.details.sum(c => c.tradeProceeds);
    }

    public get calcTaxAmount() {
        return this.details.sum(c => c.calcTaxAmount);
    }

    @Attributes.Float()
    public get effectiveSellPrice() {
        return this.details[0].effectiveSellPrice;
    }
    public set effectiveSellPrice(value: number) {
        this.details.forEach(t => t.limitPrice = value);
        if (this.lastTradeType === TradeType.SellToCover) {
            this.calcSellToBuy();
        }
    }

    public get isVested() {
        return this.vestedTranches.length === this.details.length;
    }

    public get vestingDate() {
        return this.trancheBalance.vestingDate;
    }

    public get hasAcceleration() {
        return this.details.some(c => c.hasAcceleration);
    }

    public get isTradeable() {
        return this.details.filter(c => c.hasQuantity).every(c => c.isTradeable);
    }

    public get cannotTradeReason() {
        return this.details.find(c => c.cannotTradeReason && c.hasQuantity)?.cannotTradeReason ?? "";
    }

    public get canTrade() {
        const tranchesWithQuantity = this.details.filter(c => c.hasQuantity);
        return tranchesWithQuantity.every(c => c.canTrade);
    }

    // Writable fields

    /** Sell quantity */
    @Attributes.Integer()
    public get sellQuantity() {
        return this.details.sum(c => c.sellQuantity);
    }
    public set sellQuantity(value: number) {
        this.setSellQuantity(value);
    }

    public setSellQuantity(value: number) {
        this.allocateUnits(value, "sellQuantity");
    }

    /** Buy quantity */
    @Attributes.Integer()
    public get buyQuantity() {
        return this.details.sum(c => c.buyQuantity);
    }
    public set buyQuantity(value: number) {
        this.setBuyQuantity(value);
    }

    public setBuyQuantity(value: number) {
        this.allocateUnits(value, "buyQuantity");
    }

    public get isLimitPrice() {
        return this.details[0].limitPrice !== null;
    }
    public set isLimitPrice(value: boolean) {
        if (value) {
            this.details.forEach(c => c.limitPrice = c.instrument.price)
        } else {
            this.details.forEach(t => t.limitPrice = null);
        }
        if (this.lastTradeType === TradeType.SellToCover) {
            this.calcSellToBuy();
        }
    }

    private get sortedDetails() {
        return this.details.sortBy(c => c.trancheBalance.awardDate).sortBy(c => c.trancheBalance.effectiveVestingDate);
    }

    private allocateUnits(value: number, field: "sellQuantity" | "buyQuantity") {
        value = Math.max(0, Math.min(this.availableBalance, value));

        let difference = value - this[field];
        let details = difference > 0 ? this.sortedDetails : [...this.sortedDetails].reverse();

        // First allocate without changing the quantity on the other side of the trade.
        for (let tranche of details) {
            let allocate = difference > 0 ? Math.min(difference, tranche.unitsAfterTrade) : Math.max(-tranche[field], difference);
            difference -= allocate;
            tranche[field] += allocate;
            if (difference === 0) {
                break;
            }
        }

        // If there is still quantity to allocate, reduce the quantity on the other side.
        if (difference !== 0) {
            for (let tranche of details) {
                let allocate = Math.min(difference, tranche.availableBalance - tranche[field]);
                difference -= allocate;
                tranche[field] += allocate;
                if (difference === 0) {
                    break;
                }
            }
        }
    }

    public setTradeType(type: TradeType | null) {

        if (type !== this.lastTradeType) {

            this.suppressLinkedTrancheSynchronisation(true);
            super.setTradeType(type);
            this.suppressLinkedTrancheSynchronisation(false);

            for (let tranche of this.sortedDetails) {
                tranche.lastTradeType = type;
            }

            if (this.groupLink) {
                for (let linkedGroup of this.groupLink.linkedGroups) {
                    if (linkedGroup !== this) {
                        linkedGroup.setTradeType(type);
                    }

                }
            }
        }
    }

    public calcSellToBuy(suppressTrancheSynchronisation = true) {

        runInAction(() => {
            let state: SellToBuyState | null = null;
            if (this.trancheBalance.awardLinkId && suppressTrancheSynchronisation) {
                this.suppressLinkedTrancheSynchronisation(true);
                state = new SellToBuyState(this.groupLink);
            } else {
                state = new SellToBuyState(null);
            }

            this.calcSellToBuyInternal(state);

            if (this.trancheBalance.awardLinkId && suppressTrancheSynchronisation && this.details.length) {
                this.linkedGroups.forEach(lg => {
                    lg.calcSellToBuyInternal(state!);
                });
                this.suppressLinkedTrancheSynchronisation(false);
            }
        });
    }

    public get buyValue() {
        return this.buyQuantity * this.trancheBalance.instrumentPrice;
    }

    private sellToBuySellRatio = 0;

    private calcSellToBuyInternal(state: SellToBuyState) {
        const tranches = this.sortedDetails;

        const unitTotal = tranches.sum(c => c.availableBalance);
        const remainingAwardDebt = tranches.sum(c => c.remainingAwardDebtPositive) + (state.remainingLossToCover ?? 0);
        const averageCostPrice = unitTotal === 0 ? 0 : (remainingAwardDebt / unitTotal);
        // If one or more group is making a loss, the others need to sell more to make up for it.
        const debtOffset = state.remainingLossToCover;

        // Use the limit price to change all calcs to use the safe price.
        const prevLimitPrice = this.details[0].limitPrice;
        const safePrice = prevLimitPrice ?? (this.trancheBalance.customInstrumentPrice * (1 - this.trancheBalance.incentiveScheme.sellToCoverBufferPriceReductionPercentage));
        if (prevLimitPrice === null && this.trancheBalance.incentiveScheme.sellToCoverBufferPriceReductionPercentage) {
            this.details.forEach(t => t.limitPrice = safePrice);
        }

        // Each tranche will do its own sell to buy calculation, but must use the brokerage percentage
        // of the whole group. To get this percent, use the total debt plus estimated tax.
        const gainPrice = this.trancheBalance.customInstrumentPrice - averageCostPrice;
        let sellQty = remainingAwardDebt / this.effectiveSellPrice;
        const taxAmount = unitTotal * gainPrice * this.trancheBalance.settings.taxPercentageOrDefault;
        let totalDebt = taxAmount + remainingAwardDebt;
        this.setSellQuantity(sellQty);

        state.feePercent = totalDebt === 0 ? 0 : this.brokerAccount.getBrokingFeeTotal(totalDebt, true) / totalDebt;

        for (let tranche of tranches) {
            tranche.calcSellToBuy(state, false);
        }

        // Each tranche will have a profit between zero, and the trade price. Adding this profit up for the group
        // will probably result in a profit > trade price. Adjust the quantity of the tranches down until the 
        // profit for the group is between zero and trade price.
        let adjustIndex = tranches.length - 1;
        while ((this.netProceeds - debtOffset) > safePrice && this.sellQuantity > 0 && adjustIndex >= 0) {
            tranches[adjustIndex].buyQuantity += 1;

            if (this.netProceeds < 0) {
                tranches[adjustIndex].sellQuantity += 1;
                break;
            }
            adjustIndex -= 1;
        }

        if (prevLimitPrice === null) {
            this.details.forEach(t => t.limitPrice = null);
        }
        this.sellToBuySellRatio = this.sellQuantity / this.availableBalance;
    }

    private suppressLinkedTrancheSynchronisation(suppress: boolean) {
        for (let tranche of this.details) {
            if (tranche.trancheLink) {
                tranche.trancheLink.suppressSynchronisation = suppress;
            }
        }
    }

    public setGroupLink(groupLink: GroupLink) {
        this.groupLink = groupLink;
        this.linkedGroups = groupLink.linkedGroups.filter(c => c !== this);
    }

    public finalise() {
        this.lastTradeType = this.details[0].lastTradeType;
    }

    public resetTradeValues() {
        this.unitsToTrade = this.availableBalance;
    }
}

export class SellToBuyState {

    constructor(groupLink: GroupLink | null) {

        if (groupLink) {
            // Sell everything and check if any groups make a loss.
            for (let group of groupLink.linkedGroups) {
                group.sellQuantity = group.availableBalance;
                this.remainingLossToCover += Math.max(0, -group.netProceeds)
            }
        }
    }

    public feePercent = 0;
    public remainingLossToCover = 0;
}