import React from 'react';
import { Routing } from '@singularsystems/neo-react';
import { withRouter, Switch, Route } from 'react-router';
import { AppTypes, AppService } from '../../Services/AppService';
import { IAppRoute } from '../../Services/RouteService';

import TermsAndConditionsView from '../../../Identity/Common/Views/TermsAndConditionsView';

class RouteView extends Routing.RouteView {
    
    private routeService = AppService.get(AppTypes.Shared.Services.RouteService);
    private authService = AppService.get(AppTypes.Shared.Services.STAuthenticationService);
    private termsAndConditionsService = AppService.get(AppTypes.Identity.Services.TermsAndConditionsService);

    constructor(props: any) {
        super(props);

        this.routes = this.routeService.routes;
        this.switchComponent = Switch;
        this.routeComponent = Route;

        this.getForbiddenComponent = (route) => <h2>Forbidden</h2>;
        this.getSigningInComponent = (route) => <div>Signing in...</div>;
    }

    protected isAuthorised(route: IAppRoute) {
        return super.isAuthorised(route);
    }

    protected getAuthorisedComponent(route: IAppRoute, path: string) {        

        if (!route.allowAnonymous && !this.termsAndConditionsService.userHasAccepted) {
            return <TermsAndConditionsView />
        } 

        if (!route.allowAnonymous && route.requiresParticipant !== false && !this.authService.user?.participantId) {
            const proxyService = AppService.get(AppTypes.Identity.Components.ProxyAsParticipantModalContainer);
            // The modal container has not rendered yet, give it time to be created.
            setTimeout(() => proxyService.viewModel.show(), 0);
            return proxyService.component; 
        } 

        return super.getAuthorisedComponent(route, path);
    }
}

export default withRouter(RouteView);