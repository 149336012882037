import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ContactDetailsVM from './ContactDetailsVM';
import { observer } from 'mobx-react';
import ContactUsView from '../../../Clients/Participants/Views/ContactUsView';
import { Link } from 'react-router-dom';
import InfoIcon from '../../../App/Components/Application/InfoIcon';
import UpdateContactDetailsCommand from '../Models/Commands/UpdateContactDetailsCommand';

@observer
export default class ContactDetailsView extends Views.ViewBase<ContactDetailsVM> {

    constructor(props: unknown) {
        super("Contact Details", ContactDetailsVM, props); 
    }

    public get headerTitle() {
        return "My Profile";
    }

    public render() {
        const contactDetails = this.viewModel.contactDetails;

        return (
            <div className="more-padding">
			    <h2>Contact Details</h2>

                <p className="my-4">
                    We have the following contact information on file for you. Please contact the <Link to={this.navigation.getPathForView(ContactUsView)}>Shares Office</Link> if your details are incorrect.
                </p>

                <div className="row">
                    <div className="col-xl-8 col-xxl-7">
                        <Neo.GridLayout>
                            <Neo.FormGroup bind={contactDetails.meta.firstName} className="no-border" />
                            <Neo.FormGroup bind={contactDetails.meta.lastName} className="no-border" />
                            <Neo.FormGroup bind={contactDetails.meta.mobileNumber} className="no-border" />
                            <Neo.FormGroup bind={contactDetails.meta.correspondenceEmail} className="no-border" 
                                label={<span> Notification Email <InfoIcon text="Your notification email can be different to your work email and will not affect your login credentials"/></span>} >
                                    <a href="/" onClick={e => {this.viewModel.beginEditContactDetails(); e.preventDefault()}}>Update</a>
                                </Neo.FormGroup>
                        </Neo.GridLayout>
                    </div>
                    
                </div>

                <Neo.Modal title="Edit contact details" bindModel={this.viewModel.meta.editCommand}
                    acceptButton={{ text: "Save", onClick: () => this.viewModel.saveContactDetails()}}>
                    {(command: UpdateContactDetailsCommand) => (
                        <div>
                            <p>
                                Please enter a new email address below. This email address can be different to your work email address and will not affect your login credentials
                            </p>
                            <Neo.FormGroup bind={command.meta.correspondenceEmail} label="New Notification Email Address" />
                        </div>
                    )}
                </Neo.Modal>
            </div>
        );
    }
}