import React from 'react';
import { NumberUtils, Misc, Model } from '@singularsystems/neo-core';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { BalancesTrancheSummary } from './BalancesTrancheSummary';
import { PortfolioHelpers } from '../../Components/PortfolioHelpers';
import IncentiveGroup, { VestingType } from '../../../Models/Portfolio/IncentiveGroup';
import CalculationGroup from '../../../Models/Portfolio/Calculation/CalculationGroup';

interface IBalancesGroupedGridProps {
    category: IncentiveGroup;
    vestingType: VestingType;
    editMode: boolean;
}

@observer
export class BalancesGroupedGrid extends React.Component<IBalancesGroupedGridProps> {

    public render() {
        const category = this.props.category,
            groupsWithBalance = category.groupedList.filter(c => c.availableBalance > 0),
            currencySymbols = groupsWithBalance.groupBy(c => c.instrument.displayCurrencySymbol, key => key),
            canSum = currencySymbols.length === 1,
            showInstrumentColumn = this.showInstrumentColumn(),
            showEntitiesColumn = this.showEntitiesColumn(),
            hasMultipleInstruments = category.hasMultipleInstruments();

        return (
            <NeoGrid.Grid items={groupsWithBalance} initialSort="awardDate" className="compact-editors portfolio-balance-grid">
                {(item, meta) => (
                    <>
                        {this.renderItem(item, meta, canSum, this.props.category.info.isCash, showInstrumentColumn, showEntitiesColumn, hasMultipleInstruments)}
                    </>
                )}
            </NeoGrid.Grid>
        )
    }

    private showInstrumentColumn() {
        for (var item of this.props.category.groupedList) {
            if (item.trancheBalance.incentiveScheme.instruments.length > 1) {
                return true;
            }
        }
        return false;
    }

    private showEntitiesColumn() {
        return this.props.category.groupedList.some(x => x.trancheBalance.hasParticipantEntity);
    }

    private onSchemeHover(item: CalculationGroup, isHovered: boolean) {
        if (item && item.groupLink) {
            item.groupLink.isHovered = isHovered;
        }
    }

    private onSelectedClick(e: React.MouseEvent, item: CalculationGroup) {
        const groups = this.props.category.groupedList.filter(c => c.vestedBalance > 0),
            selectAll = groups.some(c => !c.isSelected);

        groups.forEach(g => g.isSelected = selectAll);
    }

    private formatString(currencySymbol: string) {
        return `${currencySymbol} #,##0.00######;(${currencySymbol}#,##0.00######)`;
    }

    private renderItem(item: CalculationGroup, meta: Model.TransformMetaType<CalculationGroup>, canSum: boolean, isCashIncentiveGroup: boolean, showInstrumentColumn: boolean, showEntitiesColumn: boolean, hasMultipleInstruments: boolean) {
        const vestingType = this.props.vestingType,
            editMode = this.props.editMode;

        const totalProfitHeading = vestingType === VestingType.UnvestedOnly ? "Unvested potential\nprofit" : "Total potential\nprofit",
            unitsHeading = vestingType === VestingType.AllAwards ? "Total\nunits" : vestingType === VestingType.VestedOnly ? "Vested\nunits" : "Unvested\nunits";

        return (<NeoGrid.RowGroup expandProperty={meta.isExpanded}>
            <NeoGrid.Row>
                {vestingType !== VestingType.UnvestedOnly &&
                    <NeoGrid.Column
                        label={"Select all"}
                        display={meta.isSelected}
                        input={{ type: "checkbox" }}
                        headerClassName="select-all"
                        cellClassName={item.vestedBalance > 0 ? "icon-container-client-secondary" : undefined}
                        sort={false}
                        onHeaderClick={e => this.onSelectedClick(e, item)}
                        onClick={() => item.isSelected = item.vestedBalance === 0 ? false : !item.isSelected}
                        disabled={item.vestedBalance === 0}
                        data-tip={item.vestedBalance === 0 ? "This award has no vested units" : ""}
                        data-tip-pos="right" />}

                {showEntitiesColumn &&
                    <NeoGrid.Column label="Entity Name" display={meta.participantEntityName} />}

                <NeoGrid.Column
                    label="Scheme"
                    bindContext={meta.incentiveSchemeName}
                    className="text-heading"
                    headerClassName="bold"
                    cellClassName={item?.groupLink?.isHovered ? "bold" : ""}
                    onMouseEnter={() => this.onSchemeHover(item, true)} onMouseLeave={() => this.onSchemeHover(item, false)}
                    footerContent={() => ""}>
                    <span className={item.groupLink?.isHovered ? "bold" : ""}>
                        {item.groupLink && <i className="fa fa-link mr-2" data-tip={"Linked award: " + item.groupLink.awardLinkDescription} />}
                        {item.incentiveSchemeName}
                    </span>
                </NeoGrid.Column>

                {showInstrumentColumn &&
                    <NeoGrid.Column
                        label="Instrument"
                        display={meta.instrumentName}
                        headerClassName="bold"
                    />}

                <NeoGrid.Column display={meta.awardDate} hideBelow="xl" />

                <NeoGrid.Column label={unitsHeading} display={meta.availableBalance} numProps={{ formatString: "#,##0.##" }} className="bold" sum={!hasMultipleInstruments} />

                {vestingType === VestingType.AllAwards &&
                    <NeoGrid.Column label={"Vested\nunits"} display={meta.vestedBalance} numProps={{ formatString: "#,##0.##" }} hideBelow="xl" />}

                <NeoGrid.Column label={"Award\nprice"} display={meta.awardPrice} numProps={{ zeroText: "-", formatString: this.formatString(item.priceCurrencySymbol) }} hideBelow="md" />

                <NeoGrid.Column label={"Current\nprice"} cellClassName="stacked-column" alignment="right" headerClassName="bold" style={{ width: editMode ? 120 : "auto" }}>
                    {() => editMode && item.trancheBalance.fixedPrice === null ?
                        <Neo.NumberInput 
                            bind={item.meta.customPrice} 
                            numProps={{ currencySymbol: item.priceCurrencySymbol }} /> :
                        PortfolioHelpers.getProfitLoss(item.trancheBalance.priceDifference, (value, icon) =>
                            <>
                                {item.trancheBalance.fixedPrice !== null &&
                                    <i className="fa fa-tags mr-2" data-tip={"Price is fixed at " + NumberUtils.format(item.trancheBalance.fixedPrice, Misc.NumberFormat.CurrencyDecimals, item.priceCurrencySymbol)} />}
                                <div className="price-details">
                                    <strong>{NumberUtils.format(item.instrumentPrice, this.formatString(item.priceCurrencySymbol))}</strong>
                                    {item.trancheBalance.awardPriceForGain > 0 && <div>{icon} <span className="small">{NumberUtils.format(item.trancheBalance.pricePercent, Misc.NumberFormat.PercentDecimals)}</span></div>}
                                </div>
                            </>)
                    }
                </NeoGrid.Column>

                <NeoGrid.Column label={"Current gross\nvalue"} display={meta.currentValueConverted} numProps={{ currencySymbol: item.instrument.displayCurrencySymbol }} sum={canSum} hideBelow="sm" 
                    headerTooltip="Number of units x current share price (before any costs)" />

                <NeoGrid.Column label={"Award\ncosts/income"} display={meta.remainingAwardDebtConverted} numProps={{ currencySymbol: item.instrument.displayCurrencySymbol }} sum={canSum} hideBelow="md" />

                {vestingType !== VestingType.UnvestedOnly &&
                    <NeoGrid.Column
                        label={"Current vested\npotential profit"}
                        display={meta.vestedProfitLossConvertedLimited}
                        numProps={{ currencySymbol: item.instrument.displayCurrencySymbol }}
                        cellClassName={"cell-highlight-1 " + (item.vestedProfitLossConvertedLimited < 0 ? "grid-loss" : "grid-highlight") + (item.isExpanded || vestingType === VestingType.VestedOnly ? " bold" : " ")}
                        headerClassName="bold grid-highlight"
                        footerContent={(data: CalculationGroup[]) => canSum ?
                            NumberUtils.format(data.sum(c => c.limitLossToZero(c => c.vestedProfitLossConverted, true)), Misc.NumberFormat.CurrencyDecimals, item.instrument.displayCurrencySymbol) : null}
                        hideBelow="lg"
                        headerTooltip="Current profit (gross value - award costs) of vested units" />}

                {vestingType !== VestingType.VestedOnly &&
                    <NeoGrid.Column
                        label={totalProfitHeading}
                        display={meta.profitLossConvertedLimited}
                        numProps={{ currencySymbol: item.instrument.displayCurrencySymbol }}
                        cellClassName={"cell-highlight-2" + (item.profitLossConvertedLimited < 0 ? " grid-loss" : "") + (item.isExpanded ? "" : " bold")}
                        headerClassName="bold"
                        footerContent={(data: CalculationGroup[]) => canSum ?
                            NumberUtils.format(data.sum(c => c.limitLossToZero(c => c.profitLossConverted, true)), Misc.NumberFormat.CurrencyDecimals, item.instrument.displayCurrencySymbol) : null}
                        headerTooltip="Total potential profit (gross value - award costs) at the current market price for all units (vested and unvested). " />}
            </NeoGrid.Row>
            <NeoGrid.ChildRow isFullWidth childCellProps={{ className: "tranche-child-row" }}>
                <BalancesTrancheSummary group={item} />
            </NeoGrid.ChildRow>
        </NeoGrid.RowGroup>)
    }
}