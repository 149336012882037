import { NeoModel, Security } from '@singularsystems/neo-core';
import { UserType } from './UserType';
import { User } from 'oidc-client-ts';

interface ISTUserClaims {
    sub: string;
    st_usertypes: string[] | string;
    amr: string[] | string;
    st_tenant: string;
    st_proxy_participant: string;
    st_proxy_type: string;
    st_is_ids_user: string;
    st_participant: string;
}

@NeoModel
export default class STUser extends Security.OidcUser {

    constructor(private rawUser: User) {
        super(rawUser);

    }

    public get claims(): ISTUserClaims {
        return this.rawUser.profile as unknown as ISTUserClaims;
    }

    public get id() {
        return this.claims.sub;
    }

    public get tenantId() {
        return this.claims.st_tenant;
    }

    public get userType(): any {
        return this.claims.st_usertypes as UserType;
    }

    public get amr(): any {
        return this.claims.amr;
    }

    public get isProxy(): boolean {
        return !!this.claims.st_proxy_participant;
    }
    
    public get canProxy(): boolean {
        return !!this.claims.st_proxy_type;
    }
    
    public get mfaEnabled(): boolean {
        if (Array.isArray(this.amr)) {
            return this.amr.some(c => c === "mfa");
        } else {
            return this.amr === "mfa";
        }
    }
    
    public get canEnableMfa(): boolean {
        if (Array.isArray(this.amr)) {
            return !this.amr.some(c => c === "external");
        } else {
            return this.amr !== "external";
        }
    }

    public get canProxyFind(): boolean {
        return this.claims.st_proxy_type === "AnyParticipant";
    }

    /**
     * Returns true if the users credentials are stored in ids.
     * Enables features like change password.
     */
    public get isIDSUser(): boolean {
        return this.claims.st_is_ids_user === "true";
    }

    /**
     * The name of the participant this user is currently proxying on behalf of
     * If this field has a value, then the normal st_participant claim will have the 
     * proxied user's participant id, instead of the current user's participant id
     */
    public get proxyParticipant() {
        return this.claims.st_proxy_participant;
    }

    /**
     * The current participant id. If proxying, this will return the proxied user's participant id, instead of the current user's participant id.
     */
    public get participantId() {
        return this.claims.st_participant;
    }

    public isUserType(userType: UserType) {
        if (!userType) {
            return false;
        } else {
            if (Array.isArray(this.userType)) {
                return this.userType.some(ut => ut === userType || ut === UserType.PlatformAdmin);
            } else {
                return this.userType === userType || this.userType === UserType.PlatformAdmin
            }
        }
    }

    public isAdmin() {
        // only need to check client admin and will be true for platform admin
        return this.isUserType(UserType.ClientAdmin);
    }
}