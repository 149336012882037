import { Components, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import TradeRequestCommand from '../Models/Trading/Commands/TradeRequestCommand';
import LinkedTradeRequestsCommand from '../Models/Trading/Commands/LinkedTradeRequestsCommand';
import ParticipantBrokerDetail from '../../Common/Models/ParticipantBrokerDetail';
import PortfolioVM from './Portfolio/PortfolioVM';
import { AppService, Types } from '../TransactionsTypes';
import { NotificationServiceTypes, HtmlView } from '@singularsystems/neo-notifications';
import { TemplateTypes } from '../../Common/Models/TemplateTypes';
import TradeIncentiveGroup from '../Models/Trading/TradeIncentiveGroup';
import CalculationGroup from '../Models/Portfolio/Calculation/CalculationGroup';
import TradeAgreement from '../Models/Trading/TradeAgreement';
import UpdateParticipantBrokerDetailCommand from '../../Common/Models/UpdateParticipantBrokerDetailCommand';

@NeoModel
export default class TradeVM extends Views.ViewModelBase {

    constructor(
        private portfolioVM: PortfolioVM,
        private tradeApiClient = AppService.get(Types.Transactions.ApiClients.TradeApiClient),
        private templatesProcessingApiClient = AppService.get(NotificationServiceTypes.ApiClients.TemplateProcessing),
        private authService = AppService.get(Types.Shared.Services.STAuthenticationService),
        private portfolioService = AppService.get(Types.Transactions.Services.PortfolioService),
        private errorHandler = AppService.get(Types.Neo.UI.ErrorHandler)) {
        super(portfolioVM.taskRunner);

        this.createTrades();
    }

    public tradeGroups: TradeIncentiveGroup[] = [];
    private tradeRequests: (TradeRequestCommand | LinkedTradeRequestsCommand)[] = [];
    public flattenedTradeRequests: TradeRequestCommand[] = [];

    public hasBuys = false;

    public participantBrokerDetail: ParticipantBrokerDetail | null = null;

    public editBrokerDetail: UpdateParticipantBrokerDetailCommand | null = null;

    public acceptTerms: boolean = false;

    public startedSavingTrades = false;
    public hasSavedTrades = false;
    public hadSaveErrors = false;
    public savedCount = 0;
    public hasViewedTradeAgreementText = false;

    private brokerDetailsRequired() {
        // Check that Broker Details are required for buys
        if (this.portfolioService.appData!.settings.brokerDetailsRequiredOnTradeEntry) {
            if (this.tradeRequests.some(trade => trade.requiresBrokerDetails) && !this.participantBrokerDetail?.isValid) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    private async createTrades() {

        for (let group of this.portfolioVM.incentiveGroups) {
            const toTrade = group.getGroupsToTrade();
            if (toTrade.length > 0) {
                const tradeRequests = toTrade.map(c => this.createTrade(c));
                this.tradeGroups.push(new TradeIncentiveGroup(group.info, tradeRequests));
            }
        }

        for (let tradeRequest of this.tradeRequests) {
            tradeRequest.calcAllowForexCover(this.portfolioService.appData!.settings.baseCurrencyId);
            if (tradeRequest instanceof LinkedTradeRequestsCommand) {
                tradeRequest.finaliseSetup();
            }
        }

        const buyTrades = this.tradeRequests.filter(c => c.requiresBrokerDetails)
        if (buyTrades.length > 0) {
            this.hasBuys = true;
            const result = await this.taskRunner.waitFor(this.tradeApiClient.getParticipantBrokerDetails());
            this.participantBrokerDetail = ParticipantBrokerDetail.fromJSObject<ParticipantBrokerDetail>(result.data);
        }
    }

    public async getTradeAgreement(trade: TradeRequestCommand): Promise<TradeAgreement> {

        const incentiveScheme = trade.calcObject.trancheBalance.incentiveScheme;

        if (!trade.agreementText) {
            const mergeResult = await this.taskRunner.waitFor(
                this.templatesProcessingApiClient.merge(TemplateTypes.TradeAgreement, "", incentiveScheme.incentiveSchemeId.toString(),
                    { IncentiveSchemeId: incentiveScheme.incentiveSchemeId, IncentiveSchemeName: incentiveScheme.incentiveSchemeName }), { allowPost: true });

            trade.agreementText = mergeResult.mergeLayout(mergeResult.mergedText.body);
        }

        return new TradeAgreement(incentiveScheme.incentiveSchemeName, trade.agreementText);
    }

    private createTrade(group: CalculationGroup) {

        let linkedTradeCommand: LinkedTradeRequestsCommand | null = null;
        let groups = [group];

        if (group.trancheBalance.awardLinkId) {
            linkedTradeCommand = new LinkedTradeRequestsCommand();
            linkedTradeCommand.awardLinkId = group.trancheBalance.awardLinkId;
            linkedTradeCommand.awardLinkDescription = group.trancheBalance.awardLinkDescription;
            this.tradeRequests.push(linkedTradeCommand);

            groups = group.groupLink!.linkedGroups;
        }

        const tradeRequests = groups.map(g => TradeRequestCommand.fromCalculationObject(g));
        this.flattenedTradeRequests.push(...tradeRequests);

        if (linkedTradeCommand) {
            linkedTradeCommand.tradeRequests.push(...tradeRequests);
        } else {
            this.tradeRequests.push(...tradeRequests);
        }

        return linkedTradeCommand ?? tradeRequests[0];
    }

    public editBrokerDetails() {
        const command = new UpdateParticipantBrokerDetailCommand();
        if (!this.participantBrokerDetail) {
            command.participantBrokerDetail = new ParticipantBrokerDetail();
        } else {
            command.participantBrokerDetail = this.participantBrokerDetail.cloneWithIdentity();
        }
        this.editBrokerDetail = command;
    }

    public saveBrokerDetails() {
        this.taskRunner.run(async () => {
            const result = await this.tradeApiClient.updateParticipantBrokerDetails(this.editBrokerDetail!.toJSObject());
            if (this.participantBrokerDetail) {
                this.participantBrokerDetail.update(result.data);
            } else {
                this.participantBrokerDetail = ParticipantBrokerDetail.fromJSObject<ParticipantBrokerDetail>(result.data);
            }

            this.editBrokerDetail = null;
        });
    }

    private async showBrokerDetailsRequiredWarning() {

        // Get the current logged in participant

        const user = this.authService.user;

        const mergeResult = await this.taskRunner.waitFor(this.templatesProcessingApiClient.merge(TemplateTypes.BrokerDetailsRequiredWarning, "", "", { DisplayName: user?.displayName }));

        ModalUtils.showModal(
            "Broker details required",
            <HtmlView className="trade-terms" html={mergeResult.mergedText.body} suppressIsolation />,
            {
                size: "lg",
            }
        )
    }

    public submitTrades() {

        if (!this.brokerDetailsRequired()) {
            if (this.tradeRequests.every(c => c.isValid)) {
                this.startedSavingTrades = true;
                this.savedCount = 0;

                this.taskRunner.run(async () => {

                    for (let trade of this.tradeRequests) {
                        if (this.portfolioVM.paymentCountryId) {
                            trade.paymentCountryId = this.portfolioVM.paymentCountryId
                        }
                        try {
                            if (trade instanceof LinkedTradeRequestsCommand) {
                                trade.updateFromSaveResult((await this.tradeApiClient.submitLinkedTrade(trade.toJSObject())).data);
                                this.savedCount += trade.tradeRequests.length;
                            } else {
                                trade.updateFromSaveResult((await this.tradeApiClient.submitTrade(trade.toJSObject())).data);
                                this.savedCount += 1;
                            }

                        } catch (e) {
                            const parsedError: Components.IErrorDisplay = await this.errorHandler.parseError(e);
                            trade.updateFromErrorResult(parsedError.isServerError ? parsedError.header : parsedError.body);
                            this.hadSaveErrors = true;
                        }
                    }

                    this.portfolioService.expire();
                    this.portfolioVM.getInitialData();
                    this.hasSavedTrades = true;
                });
            } else {
                ModalUtils.showMessage("Submit trade", "Cannot continue, please fix all validation errors.");
            }
        } else {
            this.showBrokerDetailsRequiredWarning();
        }
    }
}