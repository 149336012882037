import { Attributes, NeoModel, NumberUtils } from '@singularsystems/neo-core';
import { Awards } from '../../../App';
import CurrencyLookup from './CurrencyLookup';

@NeoModel
export default class InstrumentLookup extends Awards.InstrumentLookupBase {

    /** The rate to convert from the instrument currency to the participants selected currency. */
    @Attributes.Observable()
    public rate: number | null = null;

    public conversionCurrency: CurrencyLookup | null = null;

    // This is set from the prices in the portfolio tranches in AppDataLookup.setup
    @Attributes.Observable()
    public price: number | null = null;

    @Attributes.Observable()
    public trackingPrice: number | null = null;

    @Attributes.Float()
    @Attributes.Observable()
    public customPrice: number | null = null;

    public get displayCurrencySymbol(): string {
        return this.rate === null ? this.currencySymbol : this.conversionCurrency!.symbol;
    }

    public getConversionText(): string | null {
        if (this.conversionCurrency && this.rate && this.rate !== 1) {
            return `1 ${this.currencyCode} = ${NumberUtils.format(this.rate, "#,##0.00##")} ${this.conversionCurrency.currencyCode}`;
        } else {
            return null;
        }
    }
}