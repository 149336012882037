import { NeoModel, ValueObject, Attributes, Validation, Utils } from '@singularsystems/neo-core';

@NeoModel
export default class TimePeriod extends ValueObject {

    public isWorkingDays: boolean = false;

    @Attributes.Integer()
    public years = 3;

    @Attributes.Integer()
    public months = 0;

    @Attributes.Integer()
    public days = 0;

    // Client only properties / methods

    @Attributes.NoTracking()
    public noTimeText = "Immediately";
    
    public get description() {
        let value = "";
        const hasYears = this.years > 0 && !this.isWorkingDays;
        const hasMonths = this.months > 0 && !this.isWorkingDays;
        const hasDays = this.days > 0 && !this.isWorkingDays;
        const itemCount = (hasYears ? 1 : 0) + (hasMonths ? 1 : 0) + (hasDays? 1 : 0);

        if (itemCount === 0) {
            return this.noTimeText;
        }

        if (hasYears) {
            if (this.years > 1) { value += `${this.years} years`; }
            else { value += `${this.years} year`; }

            if (itemCount > 1) {
                if (itemCount > 2) {
                    value += ", "
                }
                else {
                    value += " and "
                }
            }
        }

        if (hasMonths) {
            if (this.months > 1) { value += `${this.months} months` }
            else { value += `${this.months} month` }

            if (hasDays) {
                value += " and "
            }
        }
        
        if (this.days > 0)
        {
            let working = "";
            if (this.isWorkingDays) { working = "working " };
            if (this.days > 1) { value += `${this.days} ${working}days` }
            else { value += `${this.days} ${working}day` }
        }
        
        return value;
    }

    public get estimatedTotalDays() {
        return (this.years * 365) + (this.months * 30) + this.days;
    }

    public hasValue() {
        return this.years > 0 || this.months > 0 || this.days > 0;
    }

    /**
     * Will set the years and months to Zero if isWorkingDays is True
     */
    public finalise() {
        if (this.isWorkingDays) {
            this.years = 0;
            this.months = 0;
        }
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(tp => tp.isWorkingDays, "Working days is not supported, holiday dates need to be provided");
        rules.failWhen(tp => tp.years < 0, "Years must be >= 0");
        rules.failWhen(tp => tp.months < 0, "Months must be >= 0");
        rules.failWhen(tp => tp.days < 0, "Days must be >= 0");
    }

    public static fromDays(days: number) {
        const tp = Utils.createInstance(TimePeriod);
        tp.days = days;
        tp.months = 0;
        tp.years = 0
        return tp;
    }

    public static fromYears(years: number) {
        const tp = Utils.createInstance(TimePeriod);
        tp.years = years;
        return tp;
    }

    public toString() {
        return this.description;
    }
}